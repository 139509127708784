<template>
  <section class="content" data-app>
    <div class="container-fluid">
              <div class="bg-white">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode && nonmembers">Εισαγωγή καταγγελίας μη μέλους</h5>
                    <h5 class="modal-title" v-show="!editmode && !nonmembers">Εισαγωγή καταγγελίας μέλους</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία καταγγελίας</h5>
                </div>


                <form @submit.prevent="editmode ? updateComplaint() : createComplaint()">
                    <div class="modal-body">

                      <div v-if="nonmembers">

                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="Όνομα"
                                  v-model="form.name"
                                  @keyup="toUpperCase('name')"
                              ></v-text-field>
                              <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="Επώνυμο"
                                  v-model="form.surname"
                                  @keyup="toUpperCase('surname')"
                              ></v-text-field>
                              <has-error :form="form" field="surname"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                            <v-select
                              label="Νομός"
                              :items="nomoi"
                              item-text="nomos"
                              item-value="id"
                              v-model="form.nomo_id"
                              :class="{ 'is-invalid': form.errors.has('nomo_id') }"
                            ></v-select>
                            <has-error :form="form" field="nomo_id"></has-error>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="Τηλέφωνο"
                                  v-model="form.tel"
                              ></v-text-field>
                              <has-error :form="form" field="tel"></has-error>
                          </div>

                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="Email"
                                  v-model="form.email"
                              ></v-text-field>
                              <has-error :form="form" field="email"></has-error>
                          </div>
                        </div>
                        <hr>

                      </div>


                      <div class="row">
                        <div class="form-group col-md-6">
                            <v-autocomplete
                              label="Θεματική ενότητα"
                              :items="complaintTypes"
                              item-text="name"
                              item-value="id"
                              v-model="form.kid_id"
                              :class="{ 'is-invalid': form.errors.has('kid_id') }"
                              clearable
                            ></v-autocomplete>
                            <has-error :form="form" field="kid_id"></has-error>
                        </div>
                        <div class="form-group col-md-6">
                            <v-autocomplete
                              label="Προμηθευτής"
                              :items="suppliers"
                              item-text="supplier"
                              item-value="id"
                              v-model="form.sid_id"
                              :class="{ 'is-invalid': form.errors.has('sid_id') }"
                              clearable
                            ></v-autocomplete>
                            <has-error :form="form" field="sid_id"></has-error>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-6">
                            <v-select
                              label="Είδος Επικοινωνίας"
                              :items="communicationTypes"
                              v-model="form.gravity"
                              :class="{ 'is-invalid': form.errors.has('gravity') }"
                            ></v-select>
                            <has-error :form="form" field="gravity"></has-error>
                        </div>
                        <div class="form-group col-md-6">
                          <div class="v-input v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. εγγραφής</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="form.date"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="date"></has-error>
                        </div>
                      </div>


                      <div class="row">
                        <div class="form-group col-md-12">
                            <v-textarea
                                label="Καταγγελία"
                                v-model="form.comments"
                            ></v-textarea>
                            <has-error :form="form" field="comments"></has-error>
                        </div>
                      </div>

                    </div>

                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Προσθήκη</button>
                    </div>
                  </form>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>

  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                nonmembers: false,
                ready: false,
                form: new Form({

                  nonmember: 0,
                  am_id: null,
                  name: '',
                  surname: '',
                  nomo_id: '',
                  tel: '',
                  email: '',

                  id: '',
                  u_id: '',
                  am_id: '',
                  kid_id: '',
                  gravity: '',
                  comments: '',
                  date: '',
                  user_id: '',
                  ismember: '',
                  sid_id: ''
                }),

                complaintTypes: [],
                suppliers: [],
                nomoi: [],
                communicationTypes: ['', 'Καταγγελία', 'Ερώτημα', 'Παράπονο'],
            }
        },
        methods: {
          loadComplaint (){
            if(this.editmode == false)
              return;

            axios.get("/api/complaints/getOne/" + this.form.id).then(({ data }) => (this.parseFormData(data)));
          },
          parseFormData(data){
            this.form.fill(data.data);
          },
          loadComplaintTypes (){
            axios.get("/api/complaints/getTypesList").then(({ data }) => (this.complaintTypes = data.data));
          },
          loadSuppliers (){
            axios.get("/api/getSuppliers").then(({ data }) => (this.suppliers = data.data));
          },
          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => (this.nomoi = data.data));
          },
          

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          toUpperCase(formField){
              this.form[formField] = this.form[formField].toUpperCase();
          },
       
          createComplaint(){

              this.$Progress.start();

              this.form.post('/api/complaints')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();

                  let id = data.data.data.id;

                  this.$router.push({path: '/complaints/edit/' + id + '#embeded'})
                  
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateComplaint(){
              this.$Progress.start();
              this.form.put('/api/complaints/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            if(typeof this.$route.params.type != 'undefined')
            {
              if(this.$route.params.type == 'n')
              {
                this.nonmembers = true;
                this.form.nonmember = 1;
                this.loadNomoi();
              }
              else
                this.nonmembers = false;
            }

            if(typeof this.$route.params.am != 'undefined')
            {
              this.form.am_id = parseInt(this.$route.params.am)
              console.log(this.form.am_id);
            }

            if(typeof this.$route.params.id != 'undefined')
            {
              this.form.id = parseInt(this.$route.params.id)
              this.editmode = true;
              this.loadComplaint();
            }

            this.loadComplaintTypes();
            this.loadSuppliers();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
