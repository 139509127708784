<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchSyndromes()">
                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                            label="Αρ. Παραστατικού"
                            v-model="search_aapar"
                            dense
                        ></v-text-field>
                      </div>
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία Από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_date_from"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία Έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_date_to"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <v-select
                            label="Μέλη"
                            v-model="search_deleted"
                            :items="member_status"
                            item-text="name"
                            item-value="id"
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-4">
                        <v-select
                            label="Συναλλαγές"
                            v-model="search_kk"
                            :items="syndrome_kk"
                            item-text="name"
                            item-value="id"
                            dense
                        ></v-select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <button class="bg-primary btn btn-sm" type="submit">Αναζήτηση</button>
                      </div>
                    </div>
                </form>

              </div>
            </div>
            <br>

            <div class="row"><span class="col-12" v-if="sums != ''"><strong>Σύνολο χρεώσεων:</strong> {{ sums.charged }}€ - <strong>Σύνολο πιστώσεων:</strong> {{ sums.credited }}€ - <strong>Σύνολο εισφορών:</strong> {{ sums.eisfores }}€</span></div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Συναλλαγών</h3>
                <div class="card-tools">
                  <button v-if="filter != ''" type="button" class="btn btn-sm bg-info" @click="downloadSpreadsheet()">
                      <i class="fa fa-download"></i>
                      Λήψη σε excel
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Α.Μ</th>
                      <th>Επώνυμο</th>
                      <th>Όνομα</th>
                      <th>Πατρώνυμο</th>
                      <th>Κίνηση</th>
                      <th>Ημ. Κίνησης</th>
                      <th>Ποσό</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="subscription in subscriptions.data" :key="subscription.am">

                      <td>{{subscription.am_id}}</td>
                      <td>{{subscription.members.surname}}</td>
                      <td>{{subscription.members.name}}</td>
                      <td>{{subscription.members.fname}}</td>
                      <td>{{ subscriptionTypesWithKeys[subscription.kk] }}</td>
                      <td>{{ $moment(subscription.kindate).format('DD/MM/yyyy') }}</td>
                      <td>{{ subscription.poso }}</td>
                      <td>
                        <router-link :to="{ name: 'viewmember', params: { id: subscription.am_id } }">
                            <button class="bg-light btn btn-sm" title="Πληροφορίες Μέλους">
                                <i class="fa fa-list"></i>
                            </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="8" :data="subscriptions" @pagination-change-page="loadSubscriptions"></pagination>
                  <span v-if="count != ''">Αρ. Εγγραφών: {{ count }} </span>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                subscriptions : {},
                count: '',
                page: 1,
                filter: '',
                sums: '',

                form: new Form({

                }),

                months: '',

                subscriptionsFound: [],

                search_aapar: '',
                search_date_from: '',
                search_date_to: '',
                search_deleted: '0',
                search_kk: '%',

                member_status: [{id: '%', name:'Όλα τα μέλη'},{id: '0', name:'Ενεργά Μέλη'},{id: '1', name:'Μη ενεργά μέλη'}],
                syndrome_kk: [{id: '%', name:'Όλες οι πληρωμές'},{id: '2', name:'Μόνο πιστώσεις'},{id: '1', name:'Μόνο χρεώσεις'},{id: '3', name:'Μόνο εισφορές'}],
                subscriptionTypesWithKeys: {1: 'Χρέωση', 2: 'Πίστωση', 3: 'Εθελοντική είσφορα'},

            }
        },
        methods: {

          loadSubscriptions(page = 1) {
            if(page > 0)
              this.page = page;

            this.$Progress.start();
            axios.get('/api/subscriptions?page=' + page + this.filter).then(({ data }) => {
              this.count = data.data.total;
              this.subscriptions = data.data
            });

            this.$Progress.finish();
          },

          downloadSpreadsheet(){
            window.location = '/api/downloadPaymentsReport?' + this.filter;
          },

          getSums()
          {
            axios.get('/api/subscriptions/getSums?limit=100000' + this.filter).then(({ data }) => {
              this.sums = data.data;
            });
          },
     

          searchSyndromes(){
            // if(this.search_month == '')
            // {
            //   alert('Το πεδίο μήνας είναι υποχρεωτικό.');
            //   return;
            // }

            let filters = [];
            filters.push('&include_member=1');
            if(this.search_aapar.length > 0)
              filters.push('search_aapar=' + this.search_aapar);
            if(this.search_date_from != null && this.search_date_from != '')
              filters.push('search_date_from=' + this.$moment(this.search_date_from).format('yyyy-MM-DD'));
            if(this.search_date_to != null && this.search_date_to != '')
              filters.push('search_date_to=' + this.$moment(this.search_date_to).format('yyyy-MM-DD'));
              
            filters.push('search_kk=' + this.search_kk);
            filters.push('search_deleted=' + this.search_deleted);

            this.filter = filters.join('&');

            this.loadSubscriptions(1);
            this.getSums();
          },


          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },


        },
        mounted() {

        },
        created() {

        }
    }
</script>
