<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchmembers()">
                    <div class="row">
                      <div class="col-md-4">
                        <v-select
                            label="Μέλη"
                            v-model="search_deleted"
                            :items="member_status"
                            item-text="name"
                            item-value="id"
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Α.Μ. Μέλους"
                            v-model="search_am"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="ΑΔΤ"
                            v-model="search_adt"
                            dense
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                            label="Όνομα"
                            v-model="search_name"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Επώνυμο"
                            v-model="search_surname"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Τηλέφωνο"
                            v-model="search_tel"
                            dense
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-4">
                          <v-text-field
                              label="email"
                              v-model="search_email"
                              dense
                          ></v-text-field>
                        </div>
                        <div class="form-group col-md-4">
                          <v-select
                            label="Νομός"
                            :items="nomoi"
                            item-text="nomos"
                            item-value="id"
                            v-model="search_nomo_id"
                            @change="loadCities()"
                            clearable
                            dense
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <v-select
                            label="Πόλη"
                            :items="cities"
                            item-text="city"
                            item-value="id"
                            v-model="search_city_id"
                            clearable
                            dense
                          ></v-select>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <v-select
                            label="Επιτροπή"
                            v-model="search_epitropi_id"
                            :items="epitropes"
                            item-text="epitropi"
                            item-value="id"
                            clearable
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-3">
                        <v-select
                            label="Επάγγελμα"
                            v-model="search_job_id"
                            :items="jobs"
                            item-text="job"
                            item-value="jobid"
                            clearable
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-3">
                        <v-select
                            label="Έμαθε από"
                            v-model="search_posmathate_id"
                            :items="posmathate"
                            item-text="posmathate"
                            item-value="id"
                            clearable
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-3">
                        <v-select
                            label="Εγγραφή από"
                            v-model="search_eggrafiapo_id"
                            :items="eggrafiapo"
                            item-text="eggrafiapo"
                            item-value="id"
                            clearable
                            dense
                        ></v-select>
                      </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία επικοινωνίας από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_communication_date_from"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία επικοινωνίας εως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_communication_date_to"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                      <div class="col-md-4">
                        <v-select
                            label="Κατηγορία απάντησης"
                            v-model="search_communication_type"
                            :items="communicationTypes"
                            item-text="category"
                            item-value="id"
                            clearable
                            dense
                        ></v-select>
                      </div>
                    </div>


                    <div class="row">
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία εγγραφής από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_registration_date_from"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία εγγραφής έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_registration_date_to"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία διαγραφής από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_deldate_from"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία διαγραφής έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_deldate_to"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>

                    </div>

                    <div class="row mt-0">
                      <div class="col-12 pt-0">
                        <button class="bg-primary btn btn-sm" type="submit">Αναζήτηση</button>
                      </div>
                    </div>
                </form>


              </div>
            </div>


            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Μελών</h3>
                <div class="card-tools">
                    <button v-if="filter != ''" type="button" class="btn btn-sm bg-info" @click="downloadSpreadsheet()">
                        <i class="fa fa-download"></i>
                        Λήψη σε excel
                    </button>
                    <router-link :to="{ name: 'editmember' }" v-if="$gate.user.role == 'kepka_admin' || $gate.user.role == 'kepka_s_admin'">
                        <button type="button" class="btn btn-sm btn-primary text-light">
                            <i class="fa fa-plus-square"></i>
                            Προσθήκη Νέου
                        </button>
                    </router-link>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Α.Μ</th>
                      <th>Επώνυμο</th>
                      <th>Όνομα</th>
                      <th>Πατρώνυμο</th>
                      <th>Κατάσταση</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="member in members.data" :key="member.am">

                      <td>{{member.am}}</td>
                      <td>{{member.surname}}</td>
                      <td>{{member.name}}</td>
                      <td>{{member.fname}}</td>
                      <td>
                          <v-btn v-show="member.deleted == 0"
                            elevation="2"
                            x-small
                            color="#1867C0"
                            class="text-light"
                            >ΕΝΕΡΓΟ</v-btn>
                          <v-btn v-show="member.deleted == 1"
                            elevation="2"
                            x-small
                            color="#CB2939"
                            class="text-light"
                            >ΑΝΕΝΕΡΓΟ</v-btn>
                      </td>
                      <td>

                        <router-link :to="{ name: 'viewmember', params: { id: member.am } }">
                            <button class="bg-light btn btn-sm" title="Πληροφορίες Μέλους">
                                <i class="fa fa-list"></i>
                            </button>
                        </router-link>
                        <router-link :to="{ name: 'editmember', params: { id: member.am } }" v-if="$gate.user.role == 'kepka_admin' || $gate.user.role == 'kepka_s_admin'">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Μέλους">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </router-link>
                        <button v-if="member.deleted == 0 && ($gate.user.role == 'kepka_admin' || $gate.user.role == 'kepka_s_admin')" class="bg-warning btn btn-sm" title="Διακοπή συνδρομής Μέλους" @click="deleteSubscription(member.am)">
                            <i class="fa fa-times white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="8" :data="members" @pagination-change-page="loadMembers"></pagination>
                  <span v-if="count != ''">Αρ. Εγγραφών: {{ count }} </span>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                members : {},
                nomoi: [],
                cities: [],
                posmathate: [],
                eggrafiapo: [],
                jobs: [],
                epitropes: [],
                communicationTypes: [],
                member_status: [{id: '%', name:'Όλα τα μέλη'}, {id: '0', name:'Ενεργά Μέλη'}, {id: '1', name:'Μη ενεργά μέλη'}],


                count: '',
                page: 1,
                filter: '',

                form: new Form({

                }),

                search_am: '',
                search_adt: '',
                search_name: '',
                search_surname: '',
                search_tel: '',
                search_email: '',
                search_nomo_id: '',
                search_city_id: '',
                search_epitropi_id: '',
                search_job_id: '',
                search_posmathate_id: '',
                search_eggrafiapo_id: '',
                search_communication_date_from: '',
                search_communication_date_to: '',
                search_communication_type: '',
                search_registration_date_from: '',
                search_registration_date_to: '',
                search_deldate_from: '',
                search_deldate_to: '',
                search_deleted: '0',
            }
        },
        methods: {

          loadMembers(page = 1) {
            if(page > 0)
              this.page = page;

            this.$Progress.start();
            axios.get('/api/members?page=' + page + this.filter).then(({ data }) => {
              this.count = data.data.total;
              this.members = data.data;
            });
            this.$Progress.finish();
          },
          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => (this.nomoi = data.data));
          },
          loadCities (){
            axios.get("/api/getCities/" + this.search_nomo_id).then(({ data }) => (this.cities = data.data));
          },  
          loadPosmathate (){
            axios.get("/api/getPosmathate").then(({ data }) => (this.posmathate = data.data));
          },
          loadEggrafiapo (){
            axios.get("/api/getEggrafiapo").then(({ data }) => (this.eggrafiapo = data.data));
          },
          loadJobs (){
            axios.get("/api/getJobs").then(({ data }) => (this.jobs = data.data));
          },
          loadEpitropes (){
            axios.get("/api/getEpitropes").then(({ data }) => (this.epitropes = data.data));
          },
          loadCommunicationTypes() {
            axios.get('/api/communications/getTypesList').then(({ data }) => {
              this.communicationTypes = data.data;
            });
          },
          downloadSpreadsheet(){
            window.location = '/api/downloadSubscriptionsReport?' + this.filter;
          },

          // toUpperCase(formField){
          //     this.form[formField] = this.form[formField].toUpperCase();
          // },
          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },

          searchmembers(){
            let filters = [];

            if(this.search_am.length > 0)
              filters.push('search_am=' + this.search_am);
            if(this.search_adt.length > 0)
              filters.push('search_adt=' + this.search_adt);
            if(this.search_name.length > 0)
              filters.push('search_name=' + this.search_name);
            if(this.search_surname.length > 0)
              filters.push('search_surname=' + this.search_surname);
            if(this.search_tel.length > 0)
              filters.push('search_tel=' + this.search_tel);
            if(this.search_email.length > 0)
              filters.push('search_email=' + this.search_email);
            if(this.search_nomo_id > 0)
              filters.push('search_nomo_id=' + this.search_nomo_id);
            if(this.search_city_id > 0)
              filters.push('search_city_id=' + this.search_city_id);
            if(this.search_epitropi_id > 0)
              filters.push('search_epitropi_id=' + this.search_epitropi_id);
            if(this.search_job_id > 0)
              filters.push('search_job_id=' + this.search_job_id);
            if(this.search_eggrafiapo_id > 0)
              filters.push('search_eggrafiapo_id=' + this.search_eggrafiapo_id);
            if(this.search_posmathate_id > 0)
              filters.push('search_posmathate_id=' + this.search_posmathate_id);
            if(this.search_communication_type > 0)
              filters.push('search_communication_type=' + this.search_communication_type);
            if(this.search_communication_date_from != null && this.search_communication_date_from != '')
              filters.push('search_communication_date_from=' + this.$moment(this.search_communication_date_from).format('yyyy-MM-DD'));
            if(this.search_communication_date_to != null && this.search_communication_date_to != '')
              filters.push('search_communication_date_to=' + this.$moment(this.search_communication_date_to).format('yyyy-MM-DD'));
            if(this.search_registration_date_from != null && this.search_registration_date_from != '')
              filters.push('search_registration_date_from=' + this.$moment(this.search_registration_date_from).format('yyyy-MM-DD'));
            if(this.search_registration_date_to != null && this.search_registration_date_to != '')
              filters.push('search_registration_date_to=' + this.$moment(this.search_registration_date_to).format('yyyy-MM-DD'));
            if(this.search_deldate_from != null && this.search_deldate_from != '')
              filters.push('search_deldate_from=' + this.$moment(this.search_deldate_from).format('yyyy-MM-DD'));
            if(this.search_deldate_to != null && this.search_deldate_to != '')
              filters.push('search_deldate_to=' + this.$moment(this.search_deldate_to).format('yyyy-MM-DD'));

              
            filters.push('search_deleted=' + this.search_deleted);

            this.filter = '&' + filters.join('&');

            this.loadMembers(1);

          },




          deleteSubscription(id){
              Swal.fire({
                  title: 'Διακοπή συνδρομής',
                  text: "Είστε σίγουροι ότι θέλετε να διακόψετε τη συνδρομή αυτού του χρήστη; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  cancelButtonText: 'Όχι',
                  confirmButtonText: 'Ναι'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/members/'+id).then(()=>{
                                      Swal.fire(
                                      'Διακοπή επιτυχής',
                                      'Η διακοπη καταχωρήθηκε επιτυχώς.',
                                      'success'
                                      );
                              this.loadMembers(this.page);
                              }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
                        }
                  })
          },
        },
        mounted() {

        },
        created() {
            this.$Progress.start();
            this.loadNomoi();
            this.loadPosmathate();
            this.loadEggrafiapo();
            this.loadJobs();
            this.loadEpitropes();
            this.loadCommunicationTypes();
            this.searchmembers();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        }
    }
</script>
