<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchMembers()">
                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                            label="ΑΜ μέλους"
                            v-model="search_am"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Όνομα"
                            v-model="search_name"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Επώνυμο"
                            v-model="search_surname"
                            dense
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-6">
                          <v-select
                            label="Νομός"
                            :items="nomoi"
                            item-text="nomos"
                            item-value="id"
                            v-model="search_nomo_id"
                            @change="loadCities()"
                            clearable
                            dense
                          ></v-select>
                        </div>
                        <div class="form-group col-md-6">
                          <v-select
                            label="Πόλη"
                            :items="cities"
                            item-text="city"
                            item-value="id"
                            v-model="search_city_id"
                            clearable
                            dense
                          ></v-select>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                            label="Υπόλοιπο από"
                            v-model="search_balance_from"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Υπόλοιπο έως"
                            v-model="search_balance_to"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-select
                            label="Μέλη"
                            v-model="search_deleted"
                            :items="member_status"
                            item-text="name"
                            item-value="id"
                            dense
                        ></v-select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <button class="bg-primary btn btn-sm" type="submit">Αναζήτηση</button>
                      </div>
                    </div>
                </form>

              </div>
            </div>

            <br>
            <div class="row"><span class="col-12" v-if="sum != ''"><strong>Σύνολο υπολοίπων :</strong> {{ sum }}€</span></div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα υπολοίπων μελών</h3>
                <div class="card-tools">
                  <button v-if="filter != ''" type="button" class="btn btn-sm bg-info" @click="downloadSpreadsheet()">
                      <i class="fa fa-download"></i>
                      Λήψη σε excel
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Α.Μ</th>
                      <th>Επώνυμο</th>
                      <th>Όνομα</th>
                      <th>Πατρώνυμο</th>
                      <th>Τηλ. 1</th>
                      <th>Τηλ. 2</th>
                      <th>Κινητό</th>
                      <th>email</th>
                      <th>Υπόλοιπο</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="member in members.data" :key="member.am">

                      <td>{{member.am}}</td>
                      <td>{{member.surname}}</td>
                      <td>{{member.name}}</td>
                      <td>{{member.fname}}</td>
                      <td>{{member.tel1}}</td>
                      <td>{{member.tel2}}</td>
                      <td>{{member.kinito}}</td>
                      <td>{{member.email}}</td>
                      <td>{{member.balance}}</td>
                      <td>
                        <router-link :to="{ name: 'viewmember', params: { id: member.am } }">
                            <button class="bg-light btn btn-sm" title="Πληροφορίες Μέλους">
                                <i class="fa fa-list"></i>
                            </button>
                        </router-link>
                        <router-link :to="{ name: 'editmember', params: { id: member.am } }">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Μέλους">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="8" :data="members" @pagination-change-page="loadMembers"></pagination>
                  <span v-if="count != ''">Αρ. Εγγραφών: {{ count }} </span>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                members : {},
                count: '',
                sum: '',
                page: 1,
                filter: '',

                form: new Form({

                }),

                months: '',

                membersFound: [],
                search_am: '',
                search_name: '',
                search_surname: '',
                search_nomo_id: '',
                search_city_id: '',
                search_balance_from: '',
                search_balance_to: '',
                search_deleted: '0',

                member_status: [{id: '%', name:'Όλα τα μέλη'},{id: '0', name:'Ενεργά Μέλη'},{id: '1', name:'Μη ενεργά μέλη'}],
                nomoi: [],
                cities: [],
            }
        },
        methods: {

          loadMembers(page = 1) {
            if(page > 0)
              this.page = page;

            this.$Progress.start();
            axios.get('/api/members?page=' + page + this.filter).then(({ data }) => {
              this.count = data.data.total;
              this.members = data.data;
            });
            this.$Progress.finish();
          },
          downloadSpreadsheet(){
            window.location = '/api/downloadSubscriptionsReport?' + this.filter;
          },
          getSums()
          {
            axios.get('/api/members/getBalances?limit=100000' + this.filter).then(({ data }) => {
              this.sum = data.data;
            });
          },
          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => (this.nomoi = data.data));
          },
          loadCities (){
            axios.get("/api/getCities/" + this.search_nomo_id).then(({ data }) => (this.cities = data.data));
          },          

          searchMembers(){
            if(this.search_month == '')
            {
              alert('Το πεδίο μήνας είναι υποχρεωτικό.');
              return;
            }
            let filters = [];
            filters.push('&include_balance=1');
            if(this.search_am.length > 0)
              filters.push('search_am=' + this.search_am);
            if(this.search_name.length > 0)
              filters.push('search_name=' + this.search_name);
            if(this.search_surname.length > 0)
              filters.push('search_surname=' + this.search_surname);
            if(this.search_nomo_id > 0)
              filters.push('search_nomo_id=' + this.search_nomo_id);
            if(this.search_city_id > 0)
              filters.push('search_city_id=' + this.search_city_id);
            if(this.search_balance_from.length > 0)
              filters.push('search_balance_from=' + this.search_balance_from);
            if(this.search_balance_to.length > 0)
              filters.push('search_balance_to=' + this.search_balance_to);
              
            filters.push('search_deleted=' + this.search_deleted);

            this.filter = filters.join('&');

            this.loadMembers(1);
            this.getSums();
          }


        },
        mounted() {

        },
        created() {
          this.loadNomoi();
        }
    }
</script>
