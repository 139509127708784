<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchComplaints()">
                    <div class="row">
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία Από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_date_from"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία Έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_date_to"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="search_date_to"></has-error>
                        </div>
                      <div class="col-md-3">
                        <v-select
                            label="Μέλη"
                            v-model="search_member"
                            :items="member_status"
                            item-text="name"
                            item-value="id"
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-3">
                        <v-select
                            label="Είδος επικοινωνίας"
                            v-model="search_communication_type"
                            :items="communicationTypes"
                            dense
                            clearable
                        ></v-select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <button class="bg-primary btn btn-sm" type="submit">Αναζήτηση</button>
                      </div>
                    </div>
                </form>

              </div>
            </div>
            <br>


            <div class="row"><span class="col-12" v-if="sum != ''">Αρ. Καταγγελιών: <strong>{{ sum }}</strong> </span></div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Ποσοστά καταγγελιών ανά θεματική </h3>
                <div class="card-tools">
                  <button v-if="filter != ''" type="button" class="btn btn-sm bg-info" @click="downloadSpreadsheet()">
                      <i class="fa fa-download"></i>
                      Λήψη σε excel
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Αρ. τηλεφωνημάτων</th>
                      <th>Θεματική</th>
                      <th>Ποσοστό</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="complaint in complaints" :key="complaint.am">

                      <td>{{complaint.count}}</td>
                      <td>
                        <router-link :to="{ path: '/complaints/#search_date_from=' + date_from + '&search_date_to=' + date_to + '&search_member=' + search_member + '&search_kid=' + complaint.kid }">
                          {{complaint.category}}
                        </router-link>
                      </td>
                      <td>{{complaint.percentage}}%</td>

                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                complaints : {},
                communicationTypes: ['', 'Καταγγελία', 'Ερώτημα', 'Παράπονο'],
                sum: 0,
                page: 1,
                filter: '',

                form: new Form({

                }),

                months: '',

                search_date_from: '',
                search_date_to: '',
                search_member: '%',
                search_communication_type: '',

                date_from: '',
                date_to: '',

                member_status: [{id: '%', name:'Όλων'},{id: '1', name:'Μελών'},{id: '-1', name:'Μη μελών'}],
            }
        },
        methods: {

          loadComplaints() {
            this.$Progress.start();
            axios.get('/api/complaints/report?' + this.filter).then(({ data }) => {
              this.complaints = data.data;

              let sum = 0;
              for (let i = 0; i < data.data.length; i++)
                sum += data.data[i].count;    
                
              this.sum = sum;
            });
            this.$Progress.finish();
          },
     

          searchComplaints(){

            let filters = [];
            if(this.search_date_from == '' || this.search_date_to == '')
            {
              alert('Η ημερομηνίες από και έως είναι υποχρεωτικές');
              return;
            }

            this.date_from = this.$moment(this.search_date_from).format('yyyy-MM-DD');
            this.date_to = this.$moment(this.search_date_to).format('yyyy-MM-DD');

            filters.push('search_date_from=' + this.date_from);
            filters.push('search_date_to=' + this.date_to);
              
            filters.push('search_member=' + this.search_member);

            if(this.search_communication_type != null && this.search_communication_type != '')
              filters.push('search_communication_type=' + this.search_communication_type);

            this.filter = filters.join('&');

            this.loadComplaints(1)
          },


          downloadSpreadsheet(){
            window.location = '/api/downloadComplaintReport?' + this.filter;
          },


          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },


        },
        mounted() {

        },
        created() {

        }
    }
</script>
