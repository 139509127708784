<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Χρηστών</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm bg-primary white" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Προσθήκη Νέου
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Ονοματεπώνυμο</th>
                      <th>Ρόλος</th>
                      <th>Email</th>
                      <th>Ημ. Δημιουργίας</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">

                      <td>{{user.id}}</td>
                      <td>{{user.first_name + ' ' + user.last_name}}</td>
                      <td>{{user.role}}</td>
                      <td>{{user.email}}</td>
                      <td>{{$moment(user.date_joined).format('DD/MM/yyyy')}}</td>

                      <td>

                        <a href="#" @click="editModal(user)">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Χρήστη">
                                <i class="fa fa-edit"></i>
                            </button>
                        </a>
                        <a href="#" @click="deleteUser(user.id)">
                            <button class="bg-danger btn btn-sm" title="Διαγραφή Χρήστη">
                                <i class="fa fa-trash white"></i>
                            </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Δημιουργία νέου χρήστη</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία χρήστη</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.first_name"
                                label="Όνομα"
                                :class="{ 'is-invalid': form.errors.has('first_name') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="first_name"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.last_name"
                                label="Επώνυμο"
                                :class="{ 'is-invalid': form.errors.has('last_name') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="last_name"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.email"
                                label="Email"
                                :class="{ 'is-invalid': form.errors.has('email') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.username"
                                label="Όνομα Χρήστη"
                                :class="{ 'is-invalid': form.errors.has('username') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="username"></has-error>
                        </div>
                        <div class="form-group">
                            <v-text-field
                                dense
                                v-model="form.password"
                                label="Κωδικός πρόσβασης"
                                :class="{ 'is-invalid': form.errors.has('password') }"
                            >
                            </v-text-field>
                            <has-error :form="form" field="password"></has-error>
                        </div>
                        <div class="form-group">
                            <v-select
                                dense
                                v-model="form.role"
                                label="Ρόλος"
                                :class="{ 'is-invalid': form.errors.has('role') }"
                                :items="roles"
                                item-text="name"
                                item-value="name"
                            >
                            </v-select>
                            <has-error :form="form" field="role"></has-error>
                        </div>
                        <div class="form-group">
                            <v-checkbox
                                dense
                                v-model="form.is_active"
                                label="Ενεργός"
                                :true-value="1" 
                                :false-value="0"
                            >
                            </v-checkbox>
                            <has-error :form="form" field="role"></has-error>
                        </div>
                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Δημιουργία</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                form: new Form({
                    id : '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    username: '',
                    password: '',
                    role: '',
                    is_active: '',
                }),
                permissions : [],
                roles : [],
                page: 1,
            }
        },
        methods: {
            loadUsers(){
                this.$Progress.start();
                axios.get("api/user?page=" + this.page).then(({ data }) => (this.users = data.data));
                this.$Progress.finish();
            },
            getResults(page = 1) {
                this.page = page;
                this.$Progress.start();
                axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));
                this.$Progress.finish();
            },
            loadRoles(){
                axios.get("/api/user/getRolesList").then(({ data }) => (this.roles = data.data));
            },



            editModal(user){
                this.editmode = true;              
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Διαγραφή χρήστη',
                    text: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το χρήστη; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    cancelButtonText: 'Όχι',
                    confirmButtonText: 'Ναι'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Η εγγραφή έχει διαγραφεί επιτυχώς.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },

          
            createUser(){
                this.form.post('api/user')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadUsers();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },


        },
        mounted() {
            // console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadRoles();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
