<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchMembers()">
                    <div class="row">
                      <div class="col-md-4">
                          <v-select
                              dense
                              label="Μήνας"
                              item-text="name"
                              item-value="id"
                              v-model="search_month"
                              :items="months"
                              required
                              :rules="[(v) => !!v || 'Item is required']"
                          ></v-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button class="bg-primary btn btn-sm" type="submit">Αναζήτηση</button>
                      </div>
                    </div>
                </form>

              </div>
            </div>

            <br>

            <div class="row"><span class="col-12" v-if="sum != ''"><strong>Σύνολο υπολοίπων :</strong> {{ sum }}€</span></div>

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα συνδρομών μήνα</h3>
                <div class="card-tools">
                  <button v-if="filter != ''" type="button" class="btn btn-sm bg-info" @click="downloadSpreadsheet()">
                      <i class="fa fa-download"></i>
                      Λήψη σε excel
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Α.Μ</th>
                      <th>Επώνυμο</th>
                      <th>Όνομα</th>
                      <th>Πατρώνυμο</th>
                      <th>Τηλ. 1</th>
                      <th>Τηλ. 2</th>
                      <th>Κινητό</th>
                      <th>email</th>
                      <th>Υπόλοιπο</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="member in members.data" :key="member.am">

                      <td>{{member.am}}</td>
                      <td>{{member.surname}}</td>
                      <td>{{member.name}}</td>
                      <td>{{member.fname}}</td>
                      <td>{{member.tel1}}</td>
                      <td>{{member.tel2}}</td>
                      <td>{{member.kinito}}</td>
                      <td>{{member.email}}</td>
                      <td>{{member.balance}}</td>
                      <td>
                        <router-link :to="{ name: 'viewmember', params: { id: member.am } }">
                            <button class="bg-light btn btn-sm" title="Πληροφορίες Μέλους">
                                <i class="fa fa-list"></i>
                            </button>
                        </router-link>
                        <router-link :to="{ name: 'editmember', params: { id: member.am } }">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Μέλους">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="8" :data="members" @pagination-change-page="loadMembers"></pagination>
                  <span v-if="count != ''">Αρ. Εγγραφών: {{ count }} </span>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                members : {},
                count: '',
                sum: '',
                page: 1,
                filter: '',

                form: new Form({

                }),

                months: '',

                membersFound: [],
                search_month: '',

                months: [{id: '1', name:'Ιανουάριος'},{id: '2', name:'Φεβρουάριος'},{id: '3', name:'Μάρτιος'},{id: '4', name:'Απρίλιο'}, {id: '5', name:'Μάιος'},{id: '6', name:'Ιούνιος'},{id: '7', name:'Ιούλιος'},{id: '8', name:'Αύγουστος'},{id: '9', name:'Σεπτέμβριος'},{id: '10', name:'Οκτώβριος'},{id: '11', name:'Νοέμβριος'},{id: '12', name:'Δεκέμβριος'}],
                monthsWithKeys: {'1':'Ιανουάριος','2':'Φεβρουάριος','3':'Μάρτιος','4':'Απρίλιο', '5':'Μάιος','6':'Ιούνιος','7':'Ιούλιος','8':'Αύγουστος','9':'Σεπτέμβριος','10':'Οκτώβριος','11':'Νοέμβριος','12':'Δεκέμβριος',}
            }
        },
        methods: {

          loadMembers(page = 1) {
            if(page > 0)
              this.page = page;

            this.$Progress.start();
            axios.get('/api/members?page=' + page + this.filter).then(({ data }) => {
              this.count = data.data.total;
              this.members = data.data;
            });
            this.$Progress.finish();
          },

          downloadSpreadsheet(){
            window.location = '/api/downloadSubscriptionsReport?' + this.filter;
          },

          getSums()
          {
            axios.get('/api/members/getBalances?limit=100000' + this.filter).then(({ data }) => {
              this.sum = data.data;
            });
          },

          searchMembers(){
            if(this.search_month == '')
            {
              alert('Το πεδίο μήνας είναι υποχρεωτικό.');
              return;
            }
            this.filter = '&include_balance=1&search_deleted=0&search_subscription_month=' + this.search_month;
            this.loadMembers(1);
            this.getSums();
          }


        },
        mounted() {

        },
        created() {

        }
    }
</script>
