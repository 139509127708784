<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Φόρμα Αναζήτησης</h3>
              </div>
              <div class="card-body">

                <form @submit.prevent="searchComplaints()">
                    <div class="row">
                      <div class="col-md-4">
                        <v-text-field
                            label="Όνομα"
                            v-model="search_name"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Επώνυμο"
                            v-model="search_surname"
                            dense
                        ></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field
                            label="Τηλέφωνο"
                            v-model="search_tel"
                            dense
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-4">
                          <v-text-field
                              label="email"
                              v-model="search_email"
                              dense
                          ></v-text-field>
                        </div>
                        <div class="form-group col-md-4">
                          <v-select
                            label="Νομός"
                            :items="nomoi"
                            item-text="nomos"
                            item-value="id"
                            v-model="search_nomo_id"
                            clearable
                            dense
                          ></v-select>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-4">
                        <v-select
                            label="Είδος Επικοινωνίας"
                            v-model="search_gravity"
                            :items="communicationTypes"
                            clearable
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-4">
                        <v-select
                            label="Θεματική"
                            v-model="search_kid"
                            :items="complaintTypes"
                            item-text="name"
                            item-value="id"
                            clearable
                            dense
                        ></v-select>
                      </div>
                      <div class="col-md-4">
                        <v-select
                            label="Προμηθευτές"
                            v-model="search_sid"
                            :items="suppliers"
                            item-text="supplier"
                            item-value="id"
                            clearable
                            dense
                        ></v-select>
                      </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία Από</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_date_from"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--dense v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημερομηνία Έως</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="search_date_to"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                      <div class="col-md-4">
                        <v-select
                            label="Μέλη"
                            v-model="search_member"
                            :items="member_status"
                            item-text="name"
                            item-value="id"
                            dense
                        ></v-select>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-12 pt-0">
                        <button class="bg-primary btn btn-sm" type="submit">Αναζήτηση</button>
                      </div>
                    </div>
                </form>

              </div>
            </div>





            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Καταγγελιών</h3>

                <div class="card-tools">
                    
                    <router-link :to="{ name: 'newcomplaint', params:{ type: 'n' } }">
                        <button type="button" class="btn btn-sm btn-primary text-light">
                            <i class="fa fa-plus-square"></i>
                            Προσθήκη Νέας (μη μέλους)
                        </button>
                    </router-link>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Θεματική</th>
                      <th>Είδος Επικοινωνίας</th>
                      <th>Ημερομηνία καταγγελίας</th>
                      <th>Κατάσταση συνδρομητή</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="complaint in complaints.data" :key="complaint.id">

                      <td>{{complaint.id}}</td>
                      <td>{{complaintTypesWithKeys[complaint.kid_id]}}</td>
                      <td>{{complaint.gravity}}</td>
                      <td>{{$moment(complaint.date).format('DD/MM/yyyy')}}</td>
                      <td>
                          <v-btn v-show="complaint.member == 0"
                            elevation="2"
                            x-small
                            color="#CB2939"
                            class="text-light"
                            >ΜΗ ΕΝΕΡΓΟ ΜΕΛΟΣ</v-btn>
                          <v-btn v-show="complaint.member == 1"
                            elevation="2"
                            x-small
                            color="#1867C0"
                            class="text-light"
                            >ΕΝΕΡΓΟ ΜΕΛΟΣ</v-btn>
                          <v-btn v-show="complaint.member == -1"
                            elevation="2"
                            x-small
                            color="#deb318"
                            class="text-light"
                            >ΜΗ ΜΕΛΟΣ</v-btn>
                      </td>
                      <td>
                        <button class="bg-light btn btn-sm" title="Προβολή καταγγελίας" @click="viewComplaint(complaint)">
                            <i class="fa fa-list white"></i>
                        </button>
                        <router-link :to="{ name: 'editcomplaint', params: { id: complaint.id } }">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία καταγγελίας">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </router-link>
                        <button class="bg-danger btn btn-sm" title="Διαγραφή καταγγελίας" @click="deleteComplaint(complaint.id)">
                            <i class="fa fa-times white"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :limit="10" :data="complaints" @pagination-change-page="loadComplaints"></pagination>
                  <span v-if="count != ''">Αρ. Εγγραφών: {{ count }} </span>
              </div>
            </div>
            <!-- /.card -->


        <!-- Modal -->
        <div class="modal fade" id="viewComplaint" tabindex="-1" role="dialog" aria-labelledby="viewComplaint" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Προβολή Καταγγελίας</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                      <div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Καταγγέλων {{ (complaintForm.member == -1?'(μη μέλος)':(complaintForm.member == 0?'(μη ενεργό μέλος)':'(ενεργό μέλος)')) }}:</div>
                          <div class="col-7 py-1">{{ complaintForm.surname }} {{ complaintForm.name }}</div>
                        </div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Νομός:</div>
                          <div class="col-7 py-1">{{ nomoiWithKeys[complaintForm.nomo_id] }}</div>
                        </div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Στοιχεία επικοινωνίας:</div>
                          <div class="col-7 py-1">{{ complaintForm.tel }} {{ complaintForm.email }}</div>
                        </div>
                        <hr>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Είδος Επικοινωνίας:</div>
                          <div class="col-7 py-1">{{ complaintForm.gravity }}</div>
                        </div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Θεματική:</div>
                          <div class="col-7 py-1">{{ complaintTypesWithKeys[complaintForm.kid_id] }}</div>
                        </div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Προμηθευτής:</div>
                          <div class="col-7 py-1">{{ suppliersWithKeys[complaintForm.sid_id] }}</div>
                        </div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Καταγγελία:</div>
                          <div class="col-7 py-1">{{ complaintForm.comments }}</div>
                        </div>
                        <hr>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Ημ. Καταγγελίας:</div>
                          <div class="col-7 py-1">{{ $moment(complaintForm.date).format('DD/MM/yyyy') }}</div>
                        </div>
                        <div class="row my-0">
                          <div class="col-5 py-1 font-weight-bold">Χειριστής:</div>
                          <div class="col-7 py-1">{{ complaintForm.user_name }}</div>
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>





          </div>
        </div>
    </div>
    
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                count: '',
                page: 1,
                filter: '',

                complaints : {},
                complaintTypes: [],
                complaintTypesWithKeys : [],
                suppliers: [],
                suppliersWithKeys: [],
                nomoi: [],
                nomoiWithKeys: [],
                communicationTypes: ['', 'Καταγγελία', 'Ερώτημα', 'Παράπονο'],
                member_status: [{id: '%', name:'Όλων'}, {id: '2', name: 'Όλων των μελών'}, {id: '0', name:'Ενεργών μελών'}, {id: '1', name:'Μη ενεργών μελών'},{id: '-1', name:'Μη μελών'}],


                complaintForm: new Form({
                  member: 0,
                  name: '',
                  surname: '',
                  tel: '',
                  email: '',
                  nomo_id: '',
                  kid_id: '',
                  sid_id: '',
                  gravity: '',
                  comments: '',
                  date: '',
                  user_id: '',
                  user_name: '',
                }),
                form: new Form({}),


                search_name: '',
                search_surname: '',
                search_tel: '',
                search_email: '',
                search_nomo_id: '',
                search_gravity: '',
                search_sid: '',
                search_kid: '',
                search_date_from: '',
                search_date_to: '',
                search_member: '%',
            }
        },
        methods: {

          loadComplaints(page = 1) {
              if(page > 0)
                this.page = page;
              this.$Progress.start();
              axios.get('/api/complaints?page=' + page + this.filter).then(({ data }) => {
               this.count = data.data.total 
               this.complaints = data.data 
              });
              this.$Progress.finish();
          },
          loadComplaintTypes() {
              this.$Progress.start();
              axios.get('/api/complaints/getTypesList').then(({ data }) => {

                this.complaintTypes = data.data;

                let complaintTypes = {};
                for(let i = 0; i < data.data.length; i++)
                  complaintTypes[data.data[i].id] = data.data[i].name;

                this.complaintTypesWithKeys = complaintTypes;
              });
              this.$Progress.finish();
          },
          loadSuppliers (){
            axios.get("/api/getSuppliers").then(({ data }) => {
              this.suppliers = data.data;

              let suppliers = {};
              for(let i = 0; i < data.data.length; i++)
                suppliers[data.data[i].id] = data.data[i].supplier;

              this.suppliersWithKeys = suppliers;
            });
          },
          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => {
              this.nomoi = data.data

              let nomoi = {};
              for(let i = 0; i < data.data.length; i++)
                nomoi[data.data[i].id] = data.data[i].nomos;

              this.nomoiWithKeys = nomoi;
            });
          },
          loadNonMember(uid){
            axios.get("/api/members/getNonMember/" + uid).then(({ data }) => {
              this.complaintForm.name = data.data.name;
              this.complaintForm.surname = data.data.surname;
              this.complaintForm.tel = data.data.tel;
              this.complaintForm.email = data.data.email;
              this.complaintForm.nomo_id = data.data.nomo_id;
            });
          },
          loadMember(am){
            axios.get("/api/members/getOne/" + am).then(({ data }) => {
              this.complaintForm.name = data.data.name;
              this.complaintForm.surname = data.data.surname;
              this.complaintForm.tel = data.data.kinito;
              this.complaintForm.email = data.data.email;
              this.complaintForm.nomo_id = data.data.nomo_id;
            });
          },
          loadUser(user_id){
            axios.get("/api/user/getUserName/" + user_id).then(({ data }) => {
              this.complaintForm.user_name = data.data;
            });
          },


          viewComplaint(complaint){
              this.complaintForm.reset();

              if(complaint.member == -1)
                this.loadNonMember(complaint.u_id);
              else
                this.loadMember(complaint.am_id);

              this.loadUser(complaint.user_id);

              $('#viewComplaint').modal('show');
              this.complaintForm.fill(complaint);
              return false;
          },

          searchComplaints(){
            let filters = [];

            if(this.search_name.length > 0)
              filters.push('search_name=' + this.search_name);
            if(this.search_surname.length > 0)
              filters.push('search_surname=' + this.search_surname);
            if(this.search_tel.length > 0)
              filters.push('search_tel=' + this.search_tel);
            if(this.search_email.length > 0)
              filters.push('search_email=' + this.search_email);
            if(this.search_nomo_id > 0)
              filters.push('search_nomo_id=' + this.search_nomo_id);
            if(this.search_kid > 0)
              filters.push('search_kid=' + this.search_kid);
            if(this.search_sid > 0)
              filters.push('search_sid=' + this.search_sid);
            if(this.search_gravity != null && this.search_gravity.length > 0)
              filters.push('search_gravity=' + this.search_gravity);
            if(this.search_date_from != null && this.search_date_from != '')
              filters.push('search_date_from=' + this.$moment(this.search_date_from).format('yyyy-MM-DD'));
            if(this.search_date_to != null && this.search_date_to != '')
              filters.push('search_date_to=' + this.$moment(this.search_date_to).format('yyyy-MM-DD'));
              
            filters.push('search_member=' + this.search_member);

            this.filter = '&' + filters.join('&');

            this.loadComplaints(1)
          },

          loadSearchedmembers(model){
              let filter = '';
              if(this[model] != null && this[model] != '')
                filter = '&' + model + '=' + this[model];
              this.loadComplaints(1, filter);
          },

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },


          deleteComplaint(id){
                Swal.fire({
                    title: 'Διαγραφή καταγγελίας',
                    text: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την καταγγελία; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    cancelButtonText: 'Όχι',
                    confirmButtonText: 'Ναι'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/complaints/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Η εγγραφή έχει διαγραφεί επιτυχώς.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadComplaints(this.page);
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },


        },
        mounted() {
          let hash = window.location.hash;
          if(hash.length > 0)
          {
            hash = hash.substring(1);
            let parts = hash.split('&');
            for (let i = 0; i < parts.length; i++)
            {
              let tmp = parts[i].split('=');
              if(tmp[0] == 'search_member' && tmp[1] == 1)
                tmp[1] = '2';
              else if (tmp[0] == 'search_kid')
                tmp[1] = parseInt(tmp[1]);

              this[tmp[0]] = tmp[1];
            }
            setTimeout(() => {
              this.searchComplaints();
            }, 300)
          }
        },
        created() {
            this.$Progress.start();
            this.loadComplaints();
            this.loadComplaintTypes();
            this.loadSuppliers();
            this.loadNomoi();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        }
    }
</script>
