export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },


    { path: '/users', component: require('./components/Users.vue').default },


    { path: '/members', component: require('./components/members/Index.vue').default },
    { 
        path: '/members/edit/:id?', 
        component: require('./components/members/Edit.vue').default,
        props: true,
        meta: { reuse: false },
        name: 'editmember'
    },
    { path: '/members/view/:id?', component: require('./components/members/View.vue').default, name: 'viewmember' },


    { path: '/complaints', component: require('./components/complaints/Index.vue').default },
    { 
        path: '/complaints/new/:type/:am?', 
        component: require('./components/complaints/Edit.vue').default,
        props: true,
        meta: { reuse: false },
        name: 'newcomplaint'
    },
    { 
        path: '/complaints/edit/:id?', 
        component: require('./components/complaints/Edit.vue').default,
        props: true,
        meta: { reuse: false },
        name: 'editcomplaint'
    },

    //reports
    { path: '/payments', component: require('./components/reports/Payments.vue').default },
    { path: '/subscriptions', component: require('./components/reports/Subscriptions.vue').default },
    { path: '/balances', component: require('./components/reports/Balances.vue').default },
    { path: '/complaintsReport', component: require('./components/reports/Complaints.vue').default },


    //settingssettingsComplaintTypes
    { path: '/settings/complaintTypes/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/suppliers/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/eggrafiapo/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/epitropes/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/apopoumathate/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/jobs/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/nomoi/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/cities/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },
    { path: '/settings/communicationTypes/', component: require('./components/settings/Index.vue').default, meta: { reuse: false } },


   

    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    
    { path: '*', component: require('./components/NotFound.vue').default }
];
