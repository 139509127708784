<template>
    <section class="content">
        <img src="/images/logo600.jpg" alt="">
        <!-- <div class="row">
            <div class="col-md-3 col-12">
                <div class="bg-primary">
                    <div class="col-12 text-bold">Έσοδα τρέχοντος έτους</div>
                    <div class="col-12 text-bold lead">{{ sums.payments }}€</div>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="bg-danger">
                    <div class="col-12 text-bold">Έξοδα τρέχοντος έτους</div>
                    <div class="col-12 text-bold lead">{{ sums.expense_payments }}€</div>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="bg-success">
                    <div class="col-12 text-bold">Απαιτήσεις</div>
                    <div class="col-12 text-bold lead">{{ sums.unpaid_invoices }}€</div>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="bg-warning">
                    <div class="col-12 text-bold">Υποχρεώσεις</div>
                    <div class="col-12 text-bold lead">{{ sums.unpaid_expenses }}€</div>
                </div>
            </div>
        </div> -->

        <!-- <div class="row">
            <div class="col-md-6 col-12">
                <div class="card">
                    <div class="card-header"><h3 class="card-title">Υπόλοιπα Πελατών</h3> </div>
                    <div class="card-body table-responsive p-0 max-height-500">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Πελάτης</th>
                                    <th>Υπόλοιπο</th>
                                    <th>Καρτέλα</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="client in clients" :key="client.id">
                                    <td>{{client.company_name}}</td>
                                    <td>{{client.balance}}€</td>
                                    <td>
                                        <router-link :to="{ name: 'charges', params: { id: client.id } }">
                                            <button class="btn btn-light">
                                                <i class="fa fa-list black"></i>
                                            </button>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div> -->
    </section>
</template>

<script>
    export default {
        data () {
            return {
                sums: {},
                clients: [],
                suppliers: []
            }
        },
        methods: {
            loadSums(){
                axios.get("/api/dashboard/getSums").then(({ data }) => (this.sums = data.data));

            },
            loadClientData(){
                axios.get("/api/dashboard/getClientBalances").then(({ data }) => (this.clients = data.data));
            },
        },
        mounted() {
        },
        created() {
            // this.loadSums();
            // this.loadClientData();
        }
    }
</script>
