<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="row">
              <div class="col-12">
                <h4>
                  Στοιχεία Μέλους
                        <router-link :to="{ name: 'editmember', params: { id: form.am } }" v-if="$gate.user.role == 'kepka_admin' || $gate.user.role == 'kepka_s_admin'">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία Μέλους">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </router-link>
                  </h4>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>ΑΜ:</strong><br>
                <span>{{ form.am }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Ημ. Εγγραφής:</strong><br>
                <span>{{ $moment(form.subscription_date).format('DD/MM/yyyy') }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Επώνυμο:</strong><br>
                <span>{{ form.surname }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Όνομα:</strong><br>
                <span>{{ form.name }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>Όνομα Πατρός:</strong><br>
                <span>{{ form.fname }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>ΑΔΤ:</strong><br>
                <span>{{ form.adt }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Ημ. Έκδοσης:</strong><br>
                <span>{{ (form.xek != ''?$moment(form.xek).format('DD/MM/yyyy'):'') }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Αρχή έκδοσης:</strong><br>
                <span>{{ form.aek }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>Νομός:</strong><br>
                <span>{{ nomoiWithKeys[form.nomo_id] }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Πόλη:</strong><br>
                <span>{{ citiesWithKeys[form.city_id] }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>ΤΚ:</strong><br>
                <span>{{ form.zip }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Διεύθυνση:</strong><br>
                <span>{{ form.address }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>Τηλέφωνο οικίας:</strong><br>
                <span>{{ form.tel1 }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Τηλέφωνο εργασίας:</strong><br>
                <span>{{ form.tel2 }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Κινητό:</strong><br>
                <span>{{ form.kinito }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>ΑΦΜ:</strong><br>
                <span>{{ form.fax }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>Email:</strong><br>
                <span>{{ form.email }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>Εργασία:</strong><br>
                <span>{{ jobsWithKeys[form.job_id] }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Εγγραφή από:</strong><br>
                <span>{{ eggrafiapo[form.eggrafiapo_id] }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Επιτροπές:</strong><br>
                <span>{{ epitropes[form.epitropi_id] }}</span>
              </div>
              <div class="col-lg-3 col-md-6">
                <strong>Από που μάθατε:</strong><br>
                <span>{{ posmathate[form.posmathate_id] }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <strong>Κατάσταση Μέλους:</strong><br>
                <span>
                  <v-btn v-show="form.deleted == 0"
                    elevation="2"
                    x-small
                    color="#1867C0"
                    class="text-light"
                    >ΕΝΕΡΓΟ</v-btn>
                  <v-btn v-show="form.deleted == 1"
                    elevation="2"
                    x-small
                    color="#CB2939"
                    class="text-light"
                    >ΑΝΕΝΕΡΓΟ</v-btn>
                </span>
              </div>
              <div v-if="form.deleted == 1" class="col-lg-3 col-md-6">
                <strong>Ημ. διακοπής:</strong><br>
                <span>{{ $moment(form.deldate).format('DD/MM/yyyy') }}</span>
              </div>
            </div>

            <hr>

            <div v-if="$gate.user.role == 'kepka_admin' || $gate.user.role == 'kepka_s_admin'">
              <div class="row">
                <div class="col-12 pb-0">
                  <h4>Οικονομικά Στοιχεία</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pt-1">
                  <strong>Χρεώσεις: </strong><span v-if="total != null">{{ total.xreos }}€</span><br>
                  <strong>Πιστώσεις: </strong><span v-if="total != null">{{ total.pisto }}€</span><br>
                  <strong>Υπόλοιπο: </strong><span v-if="total != null">{{ total.ow }}€</span><br>
                </div>
              </div>
              <div class="card max-height-500">
                <div class="card-header">
                  <h3 class="card-title">Λίστα Χρεώσεων</h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-sm btn-primary text-light" @click="subscriptionFormModal()">
                        <i class="fa fa-plus-square"></i>
                        Νέα συναλλαγή
                    </button>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>                      
                        <th>Κωδικός συνδρομής</th>
                        <th>Ημερομηνία</th>
                        <th>Έτος</th>
                        <!-- <th>Παραστατικό</th> -->
                        <th>Ποσό</th>
                        <th>Κίνηση</th>
                        <th>Ενέργεια</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="credit in charges" :key="credit.id">

                        <td>{{ credit.id }}</td>
                        <td>{{ $moment(credit.kindate).format('DD/MM/yyyy') }}</td>
                        <td>{{ credit.etos }}</td>
                        <!-- <td>{{ credit.aapar }}</td> -->
                        <td>{{ credit.poso }}</td>
                        <td>{{ subscriptionTypesWithKeys[credit.kk] }}</td>
                        <td>
                          <button type="button" class="btn btn-sm btn-primary text-light" title="Επεξεργασία Χρέωσης" @click="subscriptionFormModal(credit)">
                              <i class="fa fa-edit"></i>
                          </button>
                          <button class="bg-danger btn btn-sm" title="Διαγραφή Χρέωσης" @click="deleteSubscription(credit.id)">
                              <i class="fa fa-trash white"></i>
                          </button>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <div class="card max-height-500">
                <div class="card-header">
                  <h3 class="card-title">Λίστα Πιστώσεων</h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-sm btn-primary text-light" @click="subscriptionFormModal()">
                        <i class="fa fa-plus-square"></i>
                        Νέα συναλλαγή
                    </button>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Κωδικός συνδρομής</th>
                        <th>Ημερομηνία</th>
                        <th>Έτος</th>
                        <th>Παραστατικό</th>
                        <th>Ποσό</th>
                        <th>Κίνηση</th>
                        <th>Ενέργεια</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="credit in credits" :key="credit.id">

                        <td>{{ credit.id }}</td>
                        <td>{{ $moment(credit.kindate).format('DD/MM/yyyy') }}</td>
                        <td>{{ credit.etos }}</td>
                        <td>{{ credit.aapar }}</td>
                        <td>{{ credit.poso }}</td>
                        <td>{{ subscriptionTypesWithKeys[credit.kk] }}</td>
                        <td>
                          <button type="button" class="btn btn-sm btn-primary text-light" title="Επεξεργασία Πίστωσης" @click="subscriptionFormModal(credit)">
                              <i class="fa fa-edit"></i>
                          </button>
                          <button class="bg-danger btn btn-sm" title="Διαγραφή Πίστωσης" @click="deleteSubscription(credit.id)">
                              <i class="fa fa-trash white"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              <br>
              <div class="row">
                <div class="col-12">
                  <h4>Επικοινωνίες</h4>
                </div>
              </div>
              <div class="card max-height-500">
                <div class="card-header">
                  <h3 class="card-title">Λίστα Επικοινωνιών</h3>
                  <div class="card-tools">
                    <button type="button" class="btn btn-sm btn-primary text-light" @click="communicationFormModal()">
                        <i class="fa fa-plus-square"></i>
                        Προσθήκη Επικοινωνίας
                    </button>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Ημερομηνία επικοινωνίας</th>
                        <th>Σχόλια</th>
                        <th>Κατηγορία απάντησης</th>
                        <th>Χειριστής</th>
                        <!-- <th>Ενέργεια</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="communication in communications.data" :key="communication.id">

                        <td>{{ $moment(communication.updated_at).format('DD/MM/yyyy') }}</td>
                        <td>{{ communication.comment }}</td>
                        <td>{{ communicationTypesWithKeys[communication.commentcategory_id] }}</td>
                        <td>{{ communication.userName }}</td>
                        <!-- <td>
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία επικοινωνίας" @click="communicationFormModal(communication)">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </td> -->

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>


            <br>

            <div class="row max-height-500">
              <div class="col-12">
                <h4>Καταγγελίες</h4>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Λίστα Καταγγελιών</h3>
                <div class="card-tools">
                  <router-link :to="{ name: 'newcomplaint', params: { type: 'm', am: this.id } }">
                      <button class="bg-primary btn btn-sm" title="Επεξεργασία καταγγελίας">
                        <i class="fa fa-plus-square"></i>
                        Προσθήκη Καταγγελίας
                      </button>
                  </router-link>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Ημερομηνία</th>
                      <th>Κατηγορία</th>
                      <th>Είδος Επικοινωνίας</th>
                      <th>Καταγγελία</th>
                      <th>Χειριστής</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="complaint in complaints.data" :key="complaint.id">

                      <td>{{ $moment(complaint.date).format('DD/MM/yyyy') }}</td>
                      <td>{{ complaintTypesWithKeys[complaint.kid_id] }}</td>
                      <td>{{ complaint.gravity }}</td>
                      <td>{{ complaint.comments }}</td>
                      <td>{{ complaint.userName }}</td>
                      <td>
                        <router-link :to="{ name: 'editcomplaint', params: { id: complaint.id } }">
                            <button class="bg-primary btn btn-sm" title="Επεξεργασία καταγγελίας">
                                <i class="fa fa-edit white"></i>
                            </button>
                        </router-link>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

    </div>



    <div class="modal fade" id="subscriptionForm" tabindex="-1" role="dialog" aria-labelledby="subscriptionForm" aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" v-show="!editsubscriptionmode">Νέα συναλλαγή</h5>
              <h5 class="modal-title" v-show="editsubscriptionmode">Επεξεργασία συναλλαγής</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>

          <form @submit.prevent="editsubscriptionmode ? updateSubscription() : createSubscription()">
              <div class="modal-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                            <v-select
                                :items="subscriptionType"
                                label="Τύπος Κίνησης"
                                item-text="name"
                                item-value="id"
                                v-model="subscriptionForm.kk"
                            ></v-select>
                        <has-error :form="subscriptionForm" field="kk"></has-error>
                    </div>
                    <div class="form-group col-md-6">
                        <v-text-field
                            label="Αρ. Παραστατικού"
                            v-model="subscriptionForm.aapar"
                        ></v-text-field>
                        <has-error :form="subscriptionForm" field="aapar"></has-error>
                    </div>
                  </div>



                  <div class="row">
                    <div class="form-group col-md-6">
                        <v-text-field
                            label="Ποσό"
                            v-model="subscriptionForm.poso"
                        ></v-text-field>
                        <has-error :form="subscriptionForm" field="poso"></has-error>
                    </div>

                    <div class="form-group col-md-6">
                        <v-text-field
                            label="Έτος"
                            v-model="subscriptionForm.etos"
                            type="number"
                        ></v-text-field>
                        <has-error :form="subscriptionForm" field="etos"></has-error>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-6">
                      <div class="v-input v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                          <div class="v-input__slot">
                            <div class="v-text-field__slot">

                              <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. Κίνησης</label>
                              <datepicker 
                                :format="customDateFormatter" 
                                v-model="subscriptionForm.kindate"
                                :clear-button="true"
                                clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                              ></datepicker>
                            </div>
                          </div>
                          <div class="v-text-field__details">
                            <div class="v-messages theme--light">
                              <div class="v-messages__wrapper"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <has-error :form="subscriptionForm" field="issue_date"></has-error>
                    </div>
                  </div>

                  <div class="row">
                      <div class="form-group col-md-12">
                          <v-textarea
                              label="Αιτιολογία"
                              v-model="subscriptionForm.ait"
                          ></v-textarea>
                          <has-error :form="subscriptionForm" field="ait"></has-error>
                      </div>
                  </div>


              </div>

              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                  <button v-show="editsubscriptionmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                  <button v-show="!editsubscriptionmode" type="submit" class="btn btn-primary">Προσθήκη</button>
              </div>
            </form>
          </div>
      </div>
  </div>




    <div class="modal fade" id="communicationForm" tabindex="-1" role="dialog" aria-labelledby="communicationForm" aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" v-show="!editcommunicationmode">Νέα επικοινωνία</h5>
              <h5 class="modal-title" v-show="editcommunicationmode">Επεξεργασία επικοινωνίας</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>

          <form @submit.prevent="editcommunicationmode ? updateCommunication() : createCommunication()">
              <div class="modal-body">

                  <div class="row">
                      <div class="form-group col-md-12">
                          <v-textarea
                              label="Σχόλιο"
                              v-model="communicationForm.comment"
                          ></v-textarea>
                          <has-error :form="communicationForm" field="comment"></has-error>
                      </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-12">
                        <v-select
                            :items="communicationTypes"
                            label="Κατηγορία Απάντησης"
                            item-text="category"
                            item-value="id"
                            v-model="communicationForm.commentcategory_id"
                        ></v-select>
                        <has-error :form="communicationForm" field="commentcategory_id"></has-error>
                    </div>
                  </div>

              </div>

              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                  <button v-show="editcommunicationmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                  <button v-show="!editcommunicationmode" type="submit" class="btn btn-primary">Προσθήκη</button>
              </div>
            </form>
          </div>
      </div>
  </div>



    
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                id: '',
                editmode: false,
                editsubscriptionmode: false,
                editcommunicationmode: false,
                form: new Form({
                  am: '',
                  name: '',
                  surname: '',
                  fname: '',
                  adt: '',
                  xek: '',
                  aek: '',
                  address: '',
                  zip: '',
                  tel1: '',
                  tel2: '',
                  kinito: '',
                  fax: '',
                  posmathate: '',
                  eggrafiapo: '',
                  email: '',
                  job: '',
                  epitropi: '',
                  regdate: '',
                  deldate: '',
                  deleted: '',
                  notes: '',
                  job_id: '',
                  posmathate_id: '',
                  eggrafiapo_id: '',
                  epitropi_id: '',
                  city_id: '',
                  nomo_id: '',
                  user_id: '',
                  subscription_date: '',
                  subscription_type_id: '',
                  subscription_isactivated: '',
                  isactive: '',
                }),
                subscriptionForm: new Form({
                  id: '',
                  am_id: '',
                  kindate: '',
                  poso: '',
                  etos: '',
                  aapar: '',
                  ait: '',
                  kk: '',
                }),
                communicationForm: new Form({
                  am_id: '',
                  comment: '',
                  commentcategory_id: '',
                }),
                nomoi: [],
                nomoiWithKeys: [],
                cities: [],
                citiesWithKeys: [],
                posmathate: [],
                eggrafiapo: [],
                epitropes: [],
                jobs: [],
                jobsWithKeys: [],

                charges: [],
                credits: [],
                total: {},

                communications: {},
                communicationTypes: [],
                communicationTypesWithKeys: [],

                complaintTypesWithKeys: [],
                complaints: {},
                subscriptionType: [{'id': 1, 'name': 'Χρέωση'}, {'id': 2, 'name': 'Πίστωση'}, {'id': 3, 'name': 'Εθελοντική είσφορα'}],
                subscriptionTypesWithKeys: {1: 'Χρέωση', 2: 'Πίστωση', 3: 'Εθελοντική είσφορα'},
            }
        },
        methods: {
          //load initial
          loadMember (){
            axios.get("/api/members/getOne/" + this.id).then(({ data }) => (this.parseFormData(data)));
          },
          parseFormData(data){
            this.form.fill(data.data);
            this.loadCities();
          },
          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => {
              this.nomoi = data.data

              let nomoi = {};
              for(let i = 0; i < data.data.length; i++)
                nomoi[data.data[i].id] = data.data[i].nomos;

              this.nomoiWithKeys = nomoi;
            });
          },
          loadCities (){
            axios.get("/api/getCities/" + this.form.nomo_id).then(({ data }) => {
              this.cities = data.data

              let cities = {};
              for(let i = 0; i < data.data.length; i++)
                cities[data.data[i].id] = data.data[i].city;

              this.citiesWithKeys = cities;
            });
          },
          loadJobs (){
            axios.get("/api/getJobs").then(({ data }) => {
              this.jobs = data.data

              let jobs = {};
              for(let i = 0; i < data.data.length; i++)
                jobs[data.data[i].jobid] = data.data[i].job;

              this.jobsWithKeys = jobs;
            });
          },
          loadEpitropes (){
            axios.get("/api/getEpitropes").then(({ data }) => {
              let epitropes = {};
              for(let i = 0; i < data.data.length; i++)
                epitropes[data.data[i].id] = data.data[i].epitropi;

              this.epitropes = epitropes;
            });
          },
          loadEggrafiapo (){
            axios.get("/api/getEggrafiapo").then(({ data }) => {
              let eggrafiapo = {};
              for(let i = 0; i < data.data.length; i++)
                eggrafiapo[data.data[i].id] = data.data[i].eggrafiapo;

              this.eggrafiapo = eggrafiapo;
            });
          },
          loadPosmathate (){
            axios.get("/api/getPosmathate").then(({ data }) => {
              let posmathate = {};
              for(let i = 0; i < data.data.length; i++)
                posmathate[data.data[i].id] = data.data[i].posmathate;

              this.posmathate = posmathate;
            });
          },

          //load fees
          loadSubscriptions(page = 1){
            axios.get('/api/subscriptions?page=' + page + "&limit=0&member_id=" + this.id).then(({ data }) => {
              let charges = [],
                  credits = [];

              for(let i = 0; i < data.data.data.length; i++)
              {
                if(data.data.data[i].kk == 1)
                {
                  charges.push(data.data.data[i]);

                }
                else
                  credits.push(data.data.data[i]);
              }
              this.charges = charges;
              this.credits = credits;
            });
          },
          loadTotals(){
              axios.get('/api/subscriptions/getTotal/' + this.id).then(({ data }) => (this.total = data.data));
          },




          //load contacts
          loadCommunications(page = 1) {
              axios.get('/api/communications?page=' + page + "&limit=0&includeUser=1&member_id=" + this.id).then(({ data }) => (this.communications = data.data));
          },
          loadCommunicationTypes(page = 1) {
            axios.get('/api/communications/getTypesList').then(({ data }) => {
              this.communicationTypes = data.data;

              let communicationTypes = {};
              for(let i = 0; i < data.data.length; i++)
                communicationTypes[data.data[i].id] = data.data[i].category;

              this.communicationTypesWithKeys = communicationTypes;
            });
          },




          //load complaints
          loadComplaints(page = 1, filter = '') {
              axios.get('/api/complaints?page=' + page + "&limit=0&includeUser=1&member_id=" + this.id).then(({ data }) => (this.complaints = data.data));
          },
          loadComplaintTypes(page = 1, filter = '') {
            axios.get('/api/complaints/getTypesList').then(({ data }) => {

              let complaintTypes = {};
              for(let i = 0; i < data.data.length; i++)
                complaintTypes[data.data[i].id] = data.data[i].name;

              this.complaintTypesWithKeys = complaintTypes;
            });
          },


          //dates
          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },


          subscriptionFormModal(subscription){
              this.subscriptionForm.reset();
              $('#subscriptionForm').modal('show');

              if(typeof subscription != 'undefined')
              {
                this.editsubscriptionmode = true;
                this.subscriptionForm.fill(subscription);
              }
              else
              {
                this.editsubscriptionmode = false;
              }

              this.subscriptionForm.am_id = this.id;
          },
          createSubscription(){
              this.$Progress.start();

              this.subscriptionForm.post('/api/subscriptions')
              .then((data)=>{
                if(data.data.success){
                  $('#subscriptionForm').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadSubscriptions();
                  this.loadTotals();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateSubscription(){
              this.$Progress.start();

              this.subscriptionForm.put('/api/subscriptions/'+this.subscriptionForm.id)
              .then((response) => {
                  // success
                  $('#subscriptionForm').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                  this.loadSubscriptions();
                  this.loadTotals();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteSubscription(id){
              Swal.fire({
                  title: 'Διαγραφή συναλλαγής',
                  text: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη συναλλαγή; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  cancelButtonText: 'Όχι',
                  confirmButtonText: 'Ναι'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.subscriptionForm.delete('/api/subscriptions/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Η εγγραφή έχει διαγραφεί επιτυχώς.',
                                      'success'
                                      );
                              this.loadSubscriptions();
                              this.loadTotals();
                              }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
                        }
                  })
          },




          communicationFormModal(communication){
              this.communicationForm.reset();
              $('#communicationForm').modal('show');

              if(typeof communication != 'undefined')
              {
                this.editcommunicationmode = true;
                this.communicationForm.fill(communication);
              }
              else
              {
                this.editcommunicationmode = false;
              }

              this.communicationForm.am_id = this.id;
          },
          createCommunication(){
              this.$Progress.start();

              this.communicationForm.post('/api/communications')
              .then((data)=>{
                if(data.data.success){
                  $('#communicationForm').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadCommunications();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCommunication(){
              this.$Progress.start();

              this.communicationForm.put('/api/communications/'+this.communicationForm.id)
              .then((response) => {
                  // success
                  $('#communicationForm').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                  this.loadCommunications();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deletecommunication(id){
              Swal.fire({
                  title: 'Διαγραφή επικοινωνίας',
                  text: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την επικοινωνία; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί.",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  cancelButtonText: 'Όχι',
                  confirmButtonText: 'Ναι'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.communicationForm.delete('/api/communications/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Η εγγραφή έχει διαγραφεί επιτυχώς.',
                                      'success'
                                      );
                              this.loadCommunications();
                              }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
                        }
                  })
          },


          
         
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            //select list
            let parts = this.$route.path.split('/');
            this.dataFilter = parts[2];
            this.id = parseInt(this.$route.params.id);

            this.loadMember();
            this.loadNomoi();
            this.loadJobs();
            this.loadEpitropes();
            this.loadEggrafiapo();
            this.loadPosmathate();

            this.loadSubscriptions();
            this.loadTotals();

            this.loadCommunications();
            this.loadCommunicationTypes();

            this.loadComplaints();
            this.loadComplaintTypes();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
    }
</script>
