<template>
  <section class="content" data-app>
    <div class="container-fluid">
              <div class="bg-white">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Εισαγωγή νέου μέλους</h5>
                    <h5 class="modal-title" v-show="editmode">Επεξεργασία μέλους</h5>
                </div>



                <form @submit.prevent="editmode ? updateMember() : createMember()">
                    <div class="modal-body">
                      <h5>Στοιχεία μέλους</h5>

                      <div class="row">
                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="*Όνομα"
                                  v-model="form.name"
                                  @keyup="toUpperCase('name')"
                                  required
                              ></v-text-field>
                              <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="*Επώνυμο"
                                  v-model="form.surname"
                                  @keyup="toUpperCase('surname')"
                                  required
                              ></v-text-field>
                              <has-error :form="form" field="surname"></has-error>
                          </div>
                          <div class="form-group col-md-4">
                              <v-text-field
                                  label="*Πατρώνυμο"
                                  v-model="form.fname"
                                  @keyup="toUpperCase('fname')"
                                  required
                              ></v-text-field>
                              <has-error :form="form" field="fname"></has-error>
                          </div>
                      </div>


                      <div class="row">
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="*ΑΔΤ"
                                v-model="form.adt"
                                @keyup="toUpperCase('adt')"
                                :class="{ 'is-invalid': form.errors.has('adt') }"
                                required
                            ></v-text-field>
                            <has-error :form="form" field="adt"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="v-input v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">*Ημ. Έκδοσης</label>
                                    <datepicker 
                                      :format="customDateFormatter" 
                                      v-model="form.xek"
                                      required
                                      :clear-button="true"
                                      clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                    ></datepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <has-error :form="form" field="xek"></has-error>
                            <!-- <v-text-field
                                label="Ημ. Έκδοσης"
                                v-model="form.xek"
                                @keyup="toUpperCase('xek')"
                            ></v-text-field>
                            <has-error :form="form" field="xek"></has-error> -->
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="*Αρχή Έκδοσης"
                                v-model="form.aek"
                                required
                                @keyup="toUpperCase('aek')"
                            ></v-text-field>
                            <has-error :form="form" field="aek"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="Email"
                                v-model="form.email"
                                :class="{ 'is-invalid': form.errors.has('email') }"
                            ></v-text-field>
                            <has-error :form="form" field="email"></has-error>
                        </div>
                      </div>


                      <div class="row">
                        <div class="form-group col-md-3">
                            <v-select
                              label="*Νομός"
                              :items="nomoi"
                              item-text="nomos"
                              item-value="id"
                              v-model="form.nomo_id"
                              :class="{ 'is-invalid': form.errors.has('nomo_id') }"
                              @change="loadCities()"
                              required
                            ></v-select>
                            <has-error :form="form" field="nomo_id"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-select
                              label="*Πόλη"
                              :items="cities"
                              item-text="city"
                              item-value="id"
                              v-model="form.city_id"
                              :class="{ 'is-invalid': form.errors.has('city_id') }"
                              required
                            ></v-select>
                            <has-error :form="form" field="city_id"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="*ΤΚ"
                                v-model="form.zip"
                                required
                            ></v-text-field>
                            <has-error :form="form" field="zip"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="*Διεύθυνση"
                                v-model="form.address"
                                @keyup="toUpperCase('address')"
                                required
                            ></v-text-field>
                            <has-error :form="form" field="address"></has-error>
                        </div>
                      </div>




                      <div class="row">
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="Τηλέφωνο Οικίας"
                                v-model="form.tel1"
                            ></v-text-field>
                            <has-error :form="form" field="tel1"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="Τηλέφωνο Εργασίας"
                                v-model="form.tel2"
                            ></v-text-field>
                            <has-error :form="form" field="tel2"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="Κινητό"
                                v-model="form.kinito"
                                :class="{ 'is-invalid': form.errors.has('kinito') }"
                            ></v-text-field>
                            <has-error :form="form" field="kinito"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-text-field
                                label="ΑΦΜ"
                                v-model="form.fax"
                            ></v-text-field>
                            <has-error :form="form" field="fax"></has-error>
                        </div>
                      </div>


                      <div class="row">
                        <div class="form-group col-md-3">
                            <v-select
                              label="Από που μάθατε για την υπηρεσία"
                              :items="posmathate"
                              item-text="posmathate"
                              item-value="id"
                              v-model="form.posmathate_id"
                              :class="{ 'is-invalid': form.errors.has('posmathate_id') }"
                              clearable
                            ></v-select>
                            <has-error :form="form" field="posmathate_id"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-select
                              label="*Τρόπος εγγραφής"
                              :items="eggrafiapo"
                              item-text="eggrafiapo"
                              item-value="id"
                              v-model="form.eggrafiapo_id"
                              :class="{ 'is-invalid': form.errors.has('eggrafiapo_id') }"
                              required
                              clearable
                            ></v-select>
                            <has-error :form="form" field="eggrafiapo_id"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-select
                              label="*Επάγγελμα"
                              :items="jobs"
                              item-text="job"
                              item-value="jobid"
                              v-model="form.job_id"
                              :class="{ 'is-invalid': form.errors.has('job_id') }"
                              required
                              clearable
                            ></v-select>
                            <has-error :form="form" field="job_id"></has-error>
                        </div>
                        <div class="form-group col-md-3">
                            <v-select
                              label="*Επιτροπή"
                              :items="epitropes"
                              item-text="epitropi"
                              item-value="id"
                              v-model="form.epitropi_id"
                              :class="{ 'is-invalid': form.errors.has('epitropi_id') }"
                              clearable
                              required
                            ></v-select>
                            <has-error :form="form" field="epitropi_id"></has-error>
                        </div>
                      </div>

                      <div class="row">
                          <div class="form-group col-md-12">
                              <v-textarea
                                  label="Σχόλια"
                                  v-model="form.notes"
                              ></v-textarea>
                              <has-error :form="form" field="notes"></has-error>
                          </div>
                      </div>

                      <hr>

                      <h5>Στοιχεία εγγραφής</h5>

                      <div class="row">
                        <div class="form-group col-md-4">
                          <div class="v-input v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <div class="v-text-field__slot">

                                  <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. εγγραφής</label>
                                  <datepicker 
                                    :format="customDateFormatter" 
                                    v-model="form.subscription_date"
                                    :clear-button="true"
                                    clear-button-icon="v-icon notranslate v-icon--link mdi mdi-close theme--light"
                                  ></datepicker>
                                </div>
                              </div>
                              <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                  <div class="v-messages__wrapper"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>


                        <div class="form-group col-md-4">
                            <v-select
                              label="Τύπος συνδρομής"
                              :items="subscription_types"
                              item-text="name"
                              item-value="id"
                              v-model="form.subscription_type_id"
                              :class="{ 'is-invalid': form.errors.has('subscription_type_id') }"
                            ></v-select>
                          <has-error :form="form" field="subscription_type_id"></has-error>
                        </div>

                        <!-- <div class="form-group col-md-3">
                          <v-select
                            label="Ενεργοποίηση συνδρομής"
                            :items="activate_options"
                            item-text="name"
                            item-value="id"
                            v-model="form.subscription_isactivated"
                            :class="{ 'is-invalid': form.errors.has('subscription_isactivated') }"
                          ></v-select>
                          <has-error :form="form" field="subscription_isactivated"></has-error>
                        </div> -->

                        <div class="form-group col-md-4">
                          <v-select
                            label="Κατάσταση μέλους"
                            :items="member_status"
                            item-text="name"
                            item-value="id"
                            v-model="form.deleted"
                            :class="{ 'is-invalid': form.errors.has('deleted') }"
                          ></v-select>
                          <has-error :form="form" field="deleted"></has-error>
                        </div>
                      </div>

                      <div class="row" v-if="editmode && form.deleted == 1">
                          <div class="form-group col-md-3">
                            <div class="v-input v-input--is-readonly theme--light v-text-field v-text-field--is-booted">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">

                                    <label for="input-24" class="v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">Ημ. διακοπής</label>
                                    <datepicker 
                                      :format="customDateFormatter" 
                                      v-model="form.deldate"
                                      :disabled="true"
                                    ></datepicker>
                                  </div>
                                </div>
                                <div class="v-text-field__details">
                                  <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <has-error :form="form" field="issue_date"></has-error>
                          </div>
                      </div>
                    </div>


                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Προσθήκη</button>
                    </div>
                  </form>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>

  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';

    export default {
      components: {
          Datepicker
        },
        data () {
            return {
                editmode: false,
                readonly: false,
                ready: false,
                form: new Form({
                  am: '',
                  name: '',
                  surname: '',
                  fname: '',
                  adt: '',
                  xek: '',
                  aek: '',
                  address: '',
                  zip: '',
                  tel1: '',
                  tel2: '',
                  kinito: '',
                  fax: '',
                  posmathate: '',
                  eggrafiapo: '',
                  email: '',
                  job: '',
                  epitropi: '',
                  regdate: '',
                  deldate: '',
                  deleted: '',
                  notes: '',
                  job_id: '',
                  posmathate_id: '',
                  eggrafiapo_id: '',
                  epitropi_id: '',
                  city_id: '',
                  nomo_id: '',
                  user_id: '',
                  subscription_date: '',
                  subscription_type_id: 132415,
                  subscription_isactivated: '',
                  isactive: '',
                }),

                nomoi: [],
                cities: [],
                posmathate: [],
                eggrafiapo: [],
                jobs: [],
                epitropes: [],
                subscription_types: [],
                activate_options: [{'id': '', 'name': 'Άγνωστο'}, {'id': 1, 'name': 'Ναι'}, {'id': 0, 'name': 'Όχι'}],
                member_status: [{'id': '', 'name': '-'}, {'id': 0, 'name': 'Ενεργό'}, {'id': 1, 'name': 'Μη ενεργό'}],
            }
        },
        methods: {
          loadMember (){
            if(this.editmode == false)
              return;

            axios.get("/api/members/getOne/" + this.form.am).then(({ data }) => (this.parseFormData(data)));
          },
          parseFormData(data){
            this.form.fill(data.data);
            this.loadCities();
          },
          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => (this.nomoi = data.data));
          },
          loadCities (){
            axios.get("/api/getCities/" + this.form.nomo_id).then(({ data }) => (this.cities = data.data));
          },
          loadPosmathate (){
            axios.get("/api/getPosmathate").then(({ data }) => (this.posmathate = data.data));
          },
          loadEggrafiapo (){
            axios.get("/api/getEggrafiapo").then(({ data }) => (this.eggrafiapo = data.data));
          },
          loadJobs (){
            axios.get("/api/getJobs").then(({ data }) => (this.jobs = data.data));
          },
          loadSubscriptionTypes (){
            axios.get("/api/getSubscriptionTypes?active=1").then(({ data }) => (this.subscription_types = data.data));
          },
          loadEpitropes (){
            axios.get("/api/getEpitropes").then(({ data }) => (this.epitropes = data.data));
          },
          

          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },
          toUpperCase(formField){
              this.form[formField] = this.form[formField].toUpperCase();
          },
       
          createMember(){

              this.$Progress.start();

              this.form.post('/api/members')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();

                  let id = data.data.data.am;

                  this.$router.push({path: '/members/view/' + id})
                  
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateMember(){
              this.$Progress.start();
              this.form.put('/api/members/'+this.form.am)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            if(this.$route.params.id > 0)
            {
              this.form.am = parseInt(this.$route.params.id)
              this.editmode = true;
              this.loadMember();
            }

            this.loadNomoi();
            this.loadPosmathate();
            this.loadEggrafiapo();
            this.loadJobs();
            this.loadSubscriptionTypes();
            this.loadEpitropes();


            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
