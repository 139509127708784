<template>
  <section class="content" data-app>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="bg-white">
              <div class="card-header">
                <h3 class="card-title">{{ header[this.dataFilter] }}</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary text-light" @click="formModal()">
                      <i class="fa fa-plus-square"></i>
                      Νέο αντικείμενο
                  </button>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>                      
                      <th>Τίτλος</th>
                      <th>Ενέργεια</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="setting in data" :key="setting.id">

                      <td>{{ setting.title }}</td>

                      <td>
                        <button type="button" class="btn btn-sm btn-primary text-light" title="Επεξεργασία Χρέωσης" @click="formModal(setting)">
                            <i class="fa fa-edit"></i>
                        </button>
                      </td>

                      <!-- <td>

                        <button class="bg-danger btn btn-sm" title="Διαγραφή Χρέωσης" @click="deleteSubscription(credit.id)">
                            <i class="fa fa-trash white"></i>
                        </button>
                      </td> -->

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>



    <div class="modal fade" id="dataForm" tabindex="-1" role="dialog" aria-labelledby="dataForm" aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" v-show="!editmode">Νέο αντικείμενο</h5>
              <h5 class="modal-title" v-show="editmode">Επεξεργασία αντικειμένου</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>

          <form @submit.prevent="editmode ? createData() : createData()">
              <div class="modal-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                        <v-text-field
                            label="Τίτλος"
                            v-model="form.title"
                        ></v-text-field>
                        <has-error :form="form" field="title"></has-error>
                    </div>
                    <div class="form-group col-md-6" v-if="dataFilter == 'jobs'">
                        <v-text-field
                            label="Κωδικός Επαγγέλματος"
                            v-model="form.jobcode"
                        ></v-text-field>
                        <has-error :form="form" field="title"></has-error>
                    </div>
                    <div class="form-group col-md-6" v-if="dataFilter == 'cities'">
                            <v-select
                                :items="nomoi"
                                label="Νομος"
                                item-text="nomos"
                                item-value="id"
                                v-model="form.nomo_id"
                            ></v-select>
                        <has-error :form="form" field="nomo_id"></has-error>
                    </div>


                  </div>

              </div>

              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Κλείσιμο</button>
                  <button v-show="editmode" type="submit" class="btn btn-success">Ενημέρωση</button>
                  <button v-show="!editmode" type="submit" class="btn btn-primary">Προσθήκη</button>
              </div>
            </form>
          </div>
      </div>
  </div>

    
  </section>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
          Datepicker
        },
        data () {
            return {
                id: '',
                editmode: false,
                form: new Form({
                  id: '',
                  title: '',
                  nomo_id: '',
                  jobcode: ''
                }),
                data: [],
                nomoi: [],

                header: {'complaintTypes': 'Θεματικές', 'suppliers': 'Προμηθευτές', 'eggrafiapo': 'Εγγραφή από', 'epitropes': 'Επιτροπές', 'apopoumathate': 'Από που μάθατε', 'jobs': 'Επαγγέλματα', 'nomoi': 'Νομοί', 'cities': 'Πόλεις', 'communicationTypes': 'Κατηγορίες απάντησης'},
            }
        },
        methods: {
          //load initial
          loadData (){
            axios.get("/api/settings?model=" + this.dataFilter).then(({ data }) => {
              this.data = data.data
            });
          },


          loadNomoi (){
            axios.get("/api/getNomoi").then(({ data }) => {
              this.nomoi = data.data

              let nomoi = {};
              for(let i = 0; i < data.data.length; i++)
                nomoi[data.data[i].id] = data.data[i].nomos;

              this.nomoiWithKeys = nomoi;
            });
          },
          // loadCities (){
          //   axios.get("/api/getCities").then(({ data }) => {
          //     this.cities = data.data

          //     let cities = {};
          //     for(let i = 0; i < data.data.length; i++)
          //       cities[data.data[i].id] = data.data[i].city;

          //     this.citiesWithKeys = cities;
          //   });
          // },
          // loadJobs (){
          //   axios.get("/api/getJobs").then(({ data }) => {
          //     this.jobs = data.data

          //     let jobs = {};
          //     for(let i = 0; i < data.data.length; i++)
          //       jobs[data.data[i].jobid] = data.data[i].job;

          //     this.jobsWithKeys = jobs;
          //   });
          // },


          //dates
          customDateFormatter(date) {
            return this.$moment(date).format('DD/MM/yyyy');
          },


          formModal(setting){
              this.form.reset();

              if(setting)
              {
                console.log(setting);
                this.form.fill(setting)
                this.editmode = true
              }

              $('#dataForm').modal('show');
          },
          createData(){
              this.$Progress.start();

              this.form.post('/api/settings?model=' + this.dataFilter)
              .then((data)=>{
                if(data.data.success){
                  $('#dataForm').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
         
         
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            //select list
            let parts = this.$route.path.split('/');
            this.dataFilter = parts[2];

            this.loadData();

            if(this.dataFilter == 'cities')
              this.loadNomoi();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
    }
</script>
